import * as React from "react"
import Img from '../images/logo-circle.jpg';
import styled, {createGlobalStyle} from "styled-components";
import {Helmet} from "react-helmet";

const Styles = createGlobalStyle`
  html {
    font-size: 100%;
    background: white;
    overflow-x: hidden;
    //pointer-events: none;
    //user-focus: none;
    //user-select: none;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0;
    background: white;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    pointer-events: auto;
  }

  input, button, select {
    border: none;
    background: none;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
  }

  * {
    font-family: neuzeit-grotesk, sans-serif;
    font-style: normal;
  }
`;

const Wrapper = styled.main`
  box-sizing: border-box;
  padding: 80px 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  h1 {
    font-family: neuzeit-grotesk, sans-serif;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 60px;
    font-size: 2.4rem;
    user-select: none;
  }
  
  img {
    width: 80vw;
    max-width: 340px;
    user-select: none;
    pointer-events: none;
   
  }
  
  .links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
    
    a {
      display: block;
      text-decoration: none;
      font-weight: 300;
      font-size: 1.4rem;
      color: black;
      transition: color 300ms ease;
      
      &:hover {
        color: darkgreen;
      }
      
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  }
`

// markup
const IndexPage = () => <>
  <Styles />
  <Helmet>
    <link rel="preconnect" href="https://p.typekit.net" crossOrigin />
    <link rel="preconnect" href="https://use.typekit.net" crossOrigin />
    <link rel="stylesheet" href="https://use.typekit.net/ibn0bcf.css" />
    <title>jō gäm dō</title>
    <meta name="description"
          content="Ginny Hwang | Plant Design" />
  </Helmet>
  <Wrapper>
    <h1>jo gäm dō</h1>
    <img src={Img} alt="" role="presentation" />
    <div className="links">
      <a href="mailto:ginny@jogamdo.farm">ginny@jogamdo.farm</a>
      <a href="tel:9176578449">917.657.8449</a>
      <a href="https://www.instagram.com/jo_gam_do/">@jo_gam_do</a>
    </div>
  </Wrapper>
</>

export default IndexPage
